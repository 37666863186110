var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    defaultStart: "-6M",
                    defaultEnd: "6M",
                    label: "LBL0000201",
                    name: "permitDates",
                  },
                  model: {
                    value: _vm.searchParam.permitDates,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "permitDates", $$v)
                    },
                    expression: "searchParam.permitDates",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-vendor", {
                  attrs: {
                    type: "search",
                    label: "LBL0000011",
                    name: "vendorCd",
                  },
                  model: {
                    value: _vm.searchParam.vendorCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "vendorCd", $$v)
                    },
                    expression: "searchParam.vendorCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    type: "search",
                    stepperGrpCd: "SWP_STEP_CD",
                    itemText: "stepperMstNm",
                    itemValue: "stepperMstCd",
                    name: "swpStepCd",
                    label: "LBLSTEPPROGRESS",
                  },
                  model: {
                    value: _vm.searchParam.swpStepCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "swpStepCd", $$v)
                    },
                    expression: "searchParam.swpStepCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    type: "search",
                    codeGroupCd: "SOP_PERMIT_TYPE_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "permitTypeCd",
                    label: "LBL0000204",
                  },
                  model: {
                    value: _vm.searchParam.permitTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "permitTypeCd", $$v)
                    },
                    expression: "searchParam.permitTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-multi-select", {
                  attrs: {
                    type: "search",
                    codeGroupCd: "SOP_SUPPLEMENT_WORK_TYPE_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "supplementWorkTypeCds",
                    label: "보충작업",
                  },
                  model: {
                    value: _vm.searchParam.supplementWorkTypeCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "supplementWorkTypeCds", $$v)
                    },
                    expression: "searchParam.supplementWorkTypeCds",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    type: "search",
                    comboItems: _vm.psmItems,
                    itemText: "codeName",
                    itemValue: "code",
                    name: "psmFlag",
                    label: "PSM 관련여부",
                  },
                  model: {
                    value: _vm.searchParam.psmFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "psmFlag", $$v)
                    },
                    expression: "searchParam.psmFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          attrs: {
            title: "LBL0000210",
            tableId: "swp01",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            merge: _vm.grid.merge,
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  _vm._v(" " + _vm._s(props.row[col.name]) + " "),
                  props.row.swpWorkRequestName
                    ? [
                        _vm._v(" - "),
                        _c(
                          "q-badge",
                          {
                            attrs: { outline: "", align: "top", color: "teal" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(props.row.swpWorkRequestName) +
                                " " +
                                _vm._s(_vm.$label("LBL0000012")) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }